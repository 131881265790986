import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
// import ApplyNowModal from "./ApplyNowModal";
import ReactMarkdown from "react-markdown";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import DescriptionBlock from "../Home/DescriptionBlock"
const StyledWrapper = styled.div`
  .margin-top {
    margin-top: 50px;
  }

  .apply-now {
    padding: 3% 0;
  }

  .flat-button {
    color: white;
    font-size: 32px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 43px;
    margin: auto;
    @media (max-width: 600px) {
      font-size: 22px;
    }
  }

  .flat-btn-link {
    margin: auto;
    display: block;
    text-decoration: none;
    text-align: center;
  }

  .flat-btn-link:hover {
    color: white;
  }

  .program-support {
    color: #000000;
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 44px;
    text-decoration: none;

    @media (max-width: 600px) {
      font-size: 15px;
      line-height: normal;
    }
  }

  .please-note-links {
    color: #000000;
    font-size: 17px;
    letter-spacing: 0;
    line-height: 27px;
    text-decoration: underline;

    @media (max-width: 600px) {
      font-size: 15px;
    }
  }

  .subtext li {
    @media (max-width: 600px) {
      margin-top: 10px;
    }
  }

  .subtext a {
    color: black;
    text-decoration: underline;
  }

  .subtext ul {
    padding-left: 0;
  }

  .toast-bar {
    color: white;
    background-color: #4dd2ff;
    font-size: 16px;
    letter-spacing: 0.5px;
  }

  .toast-bar-progress {
    background: #00bfff;
  }

  .subtext > ol {
    padding-left: 0;
  }
`;

const ApplyNowSummary = ({ data }) => {
  //comeneted due to Tc.852 change form model popup to New page
  // const [modalOpen, setModalOpen] = React.useState();
  const [urlToken, setUrlToken] = React.useState();
  useEffect(() => {
    if(window.location.search){
      // setModalOpen(true);
      // window.location.href = '/apply-now/submit'
      let token = window.location.search.replace("?", '');
      setUrlToken(token);
      window.scrollTo(0,0)
    }
  }, [])

  const notify = () =>
    toast(
      "Thank you for registering for support from upReach. We will be in contact shortly.",
      {
        className: "toast-bar",
        progressClassName: "toast-bar-progress",
        autoClose: 5000,
      }
    );

    const notifyFn1 = () =>
    toast(
      "Thank you for saving your data.",
      {
        className: "toast-bar",
        progressClassName: "toast-bar-progress",
        autoClose: 5000,
      }
    );

  return (
    <StyledWrapper>
      <ToastContainer />
      <div className="container-fluid padding-left-8 padding-right-8">
        <div className="col">
          <div className="col-12">
            <div className="margin-top">
              <p className="subtext">{data.subText}</p>
              <Link className="program-support mt-3" to="">
                {data.LinkText}
              </Link>
            </div>
            <div className="margin-top">
              <h1>{data.applyHeading}</h1>
              <ol className="subtext p-0">
              {/* <DescriptionBlock description={data.applyDescription}/> */}
                <ReactMarkdown source={data.applyDescription} />
              </ol>
            </div>
            <div className="margin-top">
              <ReactMarkdown
                className="subtext p-0"
                source={data.applyDescription2}
              />
              {/* <DescriptionBlock description={data.applyDescription}/> */}
            </div>
            {/* <div className="margin-top">
              <h2>PLEASE NOTE:</h2>
              <ol className="subtext">
                <li>
                  If you are not at University yet, simply select “Not at
                  University yet” to register your interest in the Programme. We
                  will then get in touch shortly before you start university so
                  you can complete your application.
                </li>
                <Link to="" className="please-note-links">
                  {" "}
                  See more about what you can do prior to starting University.{" "}
                </Link>
                <li>
                  If you graduated from University in 2020 and you lived in the
                  London Borough of Southwark while at Sixth Form/College,
                  please contact StephanieL on{" "}
                  <Link
                    className="please-note-links"
                    to="mailto: stephaniel@upreach.org.uk"
                  >
                    {" "}
                    stephaniel@upreach.org.uk{" "}
                  </Link>{" "}
                  so we can progress your application.{" "}
                </li>
                <Link className="please-note-links" to="">
                  See more about eligibility for the Southwark Internship
                  Programme.
                </Link>
              </ol>
            </div> */}
          </div>
        </div>
      </div>
      <div
        className="apply-now margin-top"
        style={{ background: data.CtaBgcolor }}
      >
        <span
          onClick={() =>  window.location.href = '/apply-now/submit'}
          className="flat-btn-link"
        >
          <span className="flat-button">{data.applyCta}</span>
        </span>
      </div>
      {/* comeneted due to Tc.852 change form model popup to New page */}
      {/* {modalOpen && (
        <ApplyNowModal
          setModalOpen={setModalOpen}
          modalOpen={modalOpen}
          notifyFn={notify}
          notifyFn1={notifyFn1}
          token={urlToken}
        />
      )} */}
    </StyledWrapper>
  );
};

export default ApplyNowSummary;
